<template>
  <div class="flex flex-wrap content-center h-full">
    <div class="max-w-2xl mx-auto">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="card-title text-center">
          Registrieren
        </h3>
        <p class="text-center mb-5">
          Assfinet Passwort eingeben
        </p>

        <template v-if="alerts.length > 0">
          <alert
            v-for="(alert, index) in alerts"
            :key="index"
            :type="alert.type"
            :icon="alert.icon"
            :message="alert.message"
            :link="alert.link"
            class="mt-3"
          />
        </template>
        <Form
          ref="registerForm"
          @submit="register"
        >
          <div class="mb-4">
            <BasicInput
              id="password"
              v-model="password"
              input-class="appearance-none border rounded w-full py-2 px-3 text-grey-darker border-r-0"
              type="password"
              label="Passwort"
              label-class="block text-grey-darker text-sm font-bold mb-2"
              show-button
              shadow
            />
          </div>
          <div class="mb-2">
            <BasicInput
              id="confirmpassword"
              input-class="appearance-none border rounded w-full py-2 px-3 text-grey-darker border-r-0"
              type="password"
              label="Passwort bestätigen"
              label-class="block text-grey-darker text-sm font-bold mb-2"
              rules="confirmed:@password"
              show-button
              shadow
            />
          </div>
          <button
            :disabled="registrationPending"
            type="submit"
            class="btn btn-primary-light mt-3"
          >
            <span v-if="!registrationPending">
              Registrieren
            </span>
            <i
              v-if="registrationPending"
              class="fal fa-circle-notch fa-spin"
            />
          </button>
        </Form>

        <div class="text-center mt-5">
          &copy; {{ currentYear }} – mySolution OnlineApplicationService GmbH
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BasicInput from '../components/BasicInput.vue'
import {Form} from 'vee-validate'
import Alert from '../components/Alert.vue'
import feathers from '@/feathers-client'

export default {
  components: { BasicInput, Form, Alert },
  data(){
    return{
      currentYear: new Date().getFullYear(),
      invitation: null,
      password: null,
      newUser: null,
      registrationPending: false,
      registrationError: null,
    }
  },
  computed: {
    alerts () {
      const alerts = []
      if (this.registrationError) {
        if (this.registrationError.data?.error === 'invalid_credentials') {
          alerts.push({
            type: 'danger',
            icon: 'times',
            message: 'Assfinet Daten stimmen nicht überein'
          })
        } else {
          alerts.push({
            type: 'danger',
            icon: 'times',
            message: this.registrationError.message
          })
        }
      }
      return alerts
    }
  },
  methods:{
    async register(){
      try {
        this.registrationPending = true
        this.registrationError = null

        if (!this.password) {
          this.registrationError = { message: 'Passwort ist erforderlich' }
          this.registrationPending = false
          throw new Error('Passwort ist erforderlich')
        }
        const { tenantId } = await feathers.service('activate-user').create({
          password: this.password,
          invitationToken: this.$route.params.invitationToken
        })
        this.$router.push({name: 'RegisterSuccess', params:{tenantId: tenantId}})
      } catch (error) {
        this.registrationError = error
      } finally {
        this.registrationPending = false
      }
    }
  }
}
</script>
